import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button } from "gatsby-theme-material-ui";
import { useShoppingCart } from "use-shopping-cart";
import checkImage from "../../../images/ecommerce/check.svg";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";

import { viewCartEventGA } from "../../../utils/ga-events";

const AddedProductTopSection = ({}) => {
  const { handleCartClick, cartDetails, totalPrice } = useShoppingCart();

  const handleClick = () => {
    handleCartClick();
    viewCartEventGA(cartDetails, totalPrice);
  };

  return (
    <Box
      sx={{
        marginTop: 0,
        marginBottom: 1,
      }}
    >
      <Container maxWidth={"lg"}>
        <Grid
          container
          padding={2}
          alignItems="center"
          justifyContent={"space-evenly"}
          flexDirection="column"
        >
          <Grid item xs={12} sm={10} style={{ textAlign: "center" }}>
            <img src={checkImage} width="80%"></img>
          </Grid>
          <Grid item xs={12} sm={10} marginTop={{ xs: 2 }}>
            <Typography textAlign={"center"} fontWeight="bold">
              <p style={{ margin: 0 }}>
                Your Adapter is in the cart! Head to the checkout to complete
                the payment.
              </p>
            </Typography>
          </Grid>
          <Grid
            item
            xs={10}
            marginTop={{ xs: 2 }}
            textAlign={{ xs: "center", md: "right" }}
          >
            <Button
              onClick={handleClick}
              color="primary"
              variant="contained"
              endIcon={<AddShoppingCartOutlinedIcon />}
              sx={{ color: "white" }}
            >
              Review Order
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AddedProductTopSection;
